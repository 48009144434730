import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Box } from '@core';
import { ImageCore } from '@components';
import atlasSupport from '@images/chat-widget/atlas-support.svg';

const typing = keyframes`  
  0% {
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
  }

  50% {
    transform: translateY(-5px);
    transition: all 0.5s ease-in-out;
  }

  100% {
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
  }
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  background: #d4d4d4;
  border-radius: 50%;
  margin: 3px;
  transition: all 0.5s ease-in-out;
  animation: ${typing} 1s infinite;
  animation-delay: ${({ animationDelay }) => animationDelay && `${animationDelay}ms`};
`;

const TypingBubble = () => (
  <Box
    width={72}
    height={32}
    display="flex"
    justifyContent="center"
    alignItems="center"
    backgroundColor="#f0f0f0"
    borderRadius="50px"
  >
    <Dot />
    <Dot animationDelay={-500} />
    <Dot animationDelay={-800} />
  </Box>
);

const ChatMessage = ({ children, isTyping }) => (
  <Box display="flex" alignItems="flex-start" columnGap={16}>
    <Box width={24} display="flex" mt={8} flexShrink={0}>
      <ImageCore src={atlasSupport} alt="atlas support" />
    </Box>
    {isTyping ? (
      <TypingBubble />
    ) : (
      <Box p={16} borderRadius="md" backgroundColor="#f0f0f0">
        {children}
      </Box>
    )}
  </Box>
);

export default ChatMessage;
