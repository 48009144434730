import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box } from '@core';
import { Text } from '@components';

const CloseIcon = styled.div`
  width: 12px;
  height: 2px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const SupportWindow = ({ handleToggle, children }) => {
  const { t } = useTranslation();

  return (
    <Box
      width={328}
      height={523}
      borderRadius="sm"
      backgroundColor="white"
      overflow="auto"
      display="flex"
      flexDirection="column"
    >
      <Box backgroundColor="primary" position="relative">
        <Text.Body3Strong py={10} color="white" textAlign="center">
          {t('common:chatWidget.support')}
        </Text.Body3Strong>
        <Box position="absolute" right={4} top={9} p={10} onClick={handleToggle}>
          <CloseIcon />
        </Box>
      </Box>
      <Box
        borderX="1px solid"
        borderBottom="1px solid"
        borderColor="darkBlue_20"
        borderBottomLeftRadius="sm"
        borderBottomRightRadius="sm"
        flexGrow={1}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SupportWindow;
