import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Show } from '@core';
import { ImageCore, Text } from '@components';
import supportIcon from '@images/icons/chat-widget-support-icon.svg';

const SupportBubble = ({ handleToggle }) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      columnGap={14}
      backgroundColor="primary"
      borderRadius={44}
      py={12}
      px={{ _: 12, sm: 24 }}
      onClick={handleToggle}
    >
      <Show.TabletSmallAndUp>
        <Text.Body2Strong lineHeight="20px" color="white">
          {t('common:chatWidget.support')}
        </Text.Body2Strong>
      </Show.TabletSmallAndUp>
    </Box>
  );
};

export default SupportBubble;
