import React from 'react';
import styled from 'styled-components';

import { Box } from '@core';
import { query } from '@shared/style-helpers';

import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

import { SupportBubble } from './components';
import { LinkWrapper } from '@components';

const WidgetWrapper = styled(Box)`
  @media ${query.maxMobile} {
    right: ${({ isWidgetOpen }) => (isWidgetOpen ? '50%' : '24px')};
    transform: ${({ isWidgetOpen }) => isWidgetOpen && 'translate(50%)'};
  }
`;

const ChatWidgetContainer = ({ children }) => {
  return (
    <WidgetWrapper
      isWidgetOpen={false}
      bottom={24}
      right={24}
      position="fixed"
      zIndex={110} // when opened - above checkout sticky bottom section and language picker
    >
      <LinkWrapper link="https://help.atlasvpn.com/hc/en-us" target="_blank">
        <SupportBubble
          handleToggle={() => {
            trackCustomGA4Event({ eventName: GA4_EVENTS.supportBubbleClick });
          }}
        />
      </LinkWrapper>
    </WidgetWrapper>
  );
};

export default ChatWidgetContainer;
